import Vue from 'vue';
import Vuex from 'vuex';

import {
    liApiStore
} from './modules/liApiStore.module';

import {
    liApiContent
} from './modules/liApiContent.module';


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        liApiStore: liApiStore,
        liApiContent:liApiContent
    }
});

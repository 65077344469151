<template>
  <v-row :class="`liRecapBlock ${block.type} ${block.styleClasses}`" :key="index" :ref="`ref_${block.identifier}`" :id="`${block.identifier}`">
    <div class="inner w-100 col-lg-10 mx-auto">
      <v-row class="mx-5 mb-10">
        <v-col cols="12" md="5" class="liIntro pa-0 pt-10 d-flex">
          <div class="w-100 pr-0 pr-md-15  mr-0 mr-md-10">
            <!-- TITLE -->
            <div v-html="block.titleIntro" class="pa-0 px-md-5 mb-0 text-primary" style="font-family: 'Univers LT Std Condensed', sans-serif !important;"/>
            <!-- HEADER -->
            <div v-html="block.headerIntro" class="pa-0 px-md-5 text-h4 text-md-h4" />
            <!-- TEXT -->
            <div v-html="block.textIntro" class="pa-0 px-md-5" />
          </div>
        </v-col>
        <v-col cols="12" md="7" class="liVideo pa-0">
          <div class="bg-white pa-2 pa-md-3 elevation-10 rounded-10 mx-5 mx-lg-0 mt-7">
            <v-card elevation="0" width="100%" class="m-auto">
              <v-responsive :aspect-ratio="16 / 9" width="100%">
                <iframe :src="block.videoIntro" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" style="width: 100%; height: 100%"></iframe>
              </v-responsive>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row class="mx-5 py-5">
        <v-col cols="12" md="7" class="liImages pa-0 d-flex">
          <!-- IMAGE 1 -->
          <v-img :src="`${apiPublicUrl}${block.photo1}`" aspect-ratio="1" width="calc(50% - 15px)" class="pa-5 d-none d-md-block" style="margin-right: 15px" />
          <!-- IMAGE 2 -->
          <v-img :src="`${apiPublicUrl}${block.photo2}`" aspect-ratio="1" width="50%" class="pa-5" />
        </v-col>
        <v-col cols="12" md="5" class="`liText pa-md-0 d-flex">
          <div class="w-100 pl-0 pl-md-15  ml-0 ml-md-10">
            <!-- TEXT -->
            <div v-html="block.text2" class="pa-0 px-md-10" />
            <div class="pa-0 pt-5 pb-15 pl-md-10 pt-md-5"  v-if="'instagramLink' in block || 'faceBookLink' in block ">
              <v-btn  v-if="'instagramLink' in block && block.instagramLink != ''" large class="px-0 mx-2 liInstagramBackground mb-3 mx-1 pa-0" dark icon :href="block.instagramLink" target="_blank">
                <v-icon color="white" class=""> mdi-instagram </v-icon>
              </v-btn>
              <v-btn  v-if="'faceBookLink' in block && block.faceBookLink != ''" large class="px-0 mx-2 liFacebookBackground mb-3 mx-1 pa-0" dark icon :href="block.faceBookLink" target="_blank">
                <v-icon color="white" class=""> mdi-facebook </v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-row>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  name: "RecapBlock",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {},
};
</script>
<style scoped>
.rounded-10 {
  border-radius: 10px !important;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import CountrySelect from "../views/CountrySelect.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/country-select",
    name: "CountrySelect",
    component: CountrySelect,
  },
  {
    path: "/:language?",
    name: "Home",
    component: CountrySelect,
  },
  {
    path: "/:language?/steps/:step",
    name: "Steps",
    component: () =>
      import(/* webpackChunkName: "Steps" */ "../views/Steps.vue"),
  },
  {
    path: "/:language?/share/:name",
    name: "Share",
    component: () =>
      import(/* webpackChunkName: "Steps" */ "../views/Share.vue"),
  },
  {
    path: "/:language/confirmation/:email",
    name: "Confirmation",
    component: () =>
      import(/* webpackChunkName: "PageNotFound" */ "../views/Confirmation.vue"),
  },
  {
    path: "/:language/404",
    name: "404-page",
    component: () =>
      import(/* webpackChunkName: "PageNotFound" */ "../views/PageNotFound.vue"),
  },
  {
    path: "/:language/:url",
    name: "Page",
    component: () =>
      import(/* webpackChunkName: "Steps" */ "../views/Blocks.vue"),
  },
  {
    path: "/:language/story/:url",
    name: "Story",
    component: () =>
      import(/* webpackChunkName: "Steps" */ "../views/Story.vue"),
  },
  {
    path: "*",
    name: "404-app",
    component: () =>
      import(/* webpackChunkName: "PageNotFound" */ "../views/PageNotFound.vue"),
  },

];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router;

<template>
  <v-row class="mx-0" :class="`${block.type} ${block.styleClasses}`" :key="index" :id="`${block.identifier}`" :ref="`ref_${block.identifier}`">
    <v-img :src="isAppMobile ? `${apiPublicUrl}${block.imageMobile}` : `${apiPublicUrl}${block.image}`" :position="block.imagePosition" :height="block.height" :min-height="block.minHeight" :max-height="block.maxHeight" :width="block.width" :min-width="block.minWidth" :max-width="block.maxWidth" :class="`hero liHeroBlock2 ${block.imageStyleClasses}`" :aspect-ratio="isAppMobile ? block.aspectRatioMobile : block.aspectRatio">
      <div :class="`inner ${block.innerStyleClasses}`">
        <div :class="`leftBlock ${block.innerTextStyleClasses}`">
          <h1 :class="`liTitle ${block.titleStyleClasses}`" v-html="block.title" />
          <div v-if="block.subtitle != ''" :class="`liSubtitle ${block.subtitleStyleClasses}`" style="z-index: 2; position: relative" v-html="block.subtitle" />
        </div>
      </div>
    </v-img>
  </v-row>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  name: "recapHero",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    video() {
      if ("video" in this.block && this.block.video != "") {
        if (this.isAppMobile) {
          return this.block.videoMobile;
        } else {
          return this.block.video;
        }
      } else {
        return false;
      }
    },
  },
  methods: {},
};
</script>

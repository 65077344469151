<template>
  <v-row
    :class="`liImageTextButtons ${block.type} ${block.styleClasses}`"
    :key="index"
    :ref="`ref_${block.identifier}`"
    :id="`${block.identifier}`"
  >
    <div
      class="inner row"
      :class="`${block.innerStyleClasses}`"
    >
      <v-col
        cols="12"
        md="6"
        class="liImage pa-0"
      >
        <!-- IMAGE -->
        <v-img :src="`${apiPublicUrl}${block.image}`" :aspect-ratio="4/4"/>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="liText"
      >
        <div class="m-auto w-100">
          <!-- TEXT -->
          <div v-html="block.text" class="pa-0 pa-md-5"/>
          <!-- BUTTONS -->
          <div
            class="mt-10"
            v-if="'buttons' in block && block.buttons.length > 0"
          >
            <template v-for="(button,index) in block.buttons">

              <v-btn
                :key="index"
                :outlined="button.outlined"
                :plain="button.plain"
                :rounded="button.rounded"
                :class="`liBlockButton ${button.styleClasses}`"
                @click.prevent="liBtnGo(button)"
              >
                {{button.label}}
              </v-btn>
            </template>
          </div>
        </div>

      </v-col>
    </div>
  </v-row>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  name: "imageTextButtons",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {},
};
</script>

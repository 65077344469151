import axios from "axios";
class LiApiService {
  getStores() {
    const baseURI = "https://stores.justbrands.eu/stores/brandAllTest/pme";
    const body = {};
    const config = {
      headers: {
        "Content-Type": "application/json"
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  postForm(_data) {
    const config = {
      method: "post",
      url: "https://stores.justbrands.eu/flywithdeliver/insert",
      headers: {
        "Content-Type": "application/json",
      },
      data: _data,
    };
    return axios(config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  postForm2(_data) {
    const config = {
      method: "post",
      url: "https://stores.justbrands.eu/flywithdeliver/confirm",
      headers: {
        "Content-Type": "application/json",
      },
      data: _data,
    };
    return axios(config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  postImage(_data) {
    const config = {
      method: "post",
      url: "https://stores.justbrands.eu/flywithdeliver/socialFile",
      headers: {
        "Content-Type": "application/json",
      },
      data: _data,
    };
    return axios(config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
}
export default new LiApiService();

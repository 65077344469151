<template>
  <v-row
    class="mx-0 "
    :class="`${block.type} ${block.styleClasses}`"
    :key="index"
    :id="`${block.identifier}`"
    :ref="`ref_${block.identifier}`"
  >
    <v-img
      :src="`${apiPublicUrl}${block.image}`"
      :position="block.imagePosition"
      :height="block.height"
      :min-height="block.minHeight"
      :max-height="block.maxHeight"
      :width="block.width"
      :min-width="block.minWidth"
      :max-width="block.maxWidth"
      :class="`hero liHeroBlock2 ${block.imageStyleClasses}`"
    >
      <video
        v-if="video"
        autoplay
        playsinline
        loop
        preload
        muted
        :style="`position:absolute; width:100%; height:100%; object-fit:cover; object-position: ${videoPosition};`"
      >
        <source
          :src="video"
          type="video/mp4"
        >
      </video>
      <div 
          :class="`inner ${block.innerStyleClasses}`"
        >

        <div 
          :class="`leftBlock ${block.innerTextStyleClasses}`"
        >
          <!-- TITLE -->
          <h1
            :class="`liTitle ${block.titleStyleClasses}`"
            v-html="block.title"
          />
          <!-- SUBTITLE -->
          <div
            v-if="block.subtitle != ''"
            :class="`liSubtitle ${block.subtitleStyleClasses}`"
            style="z-index: 2; position: relative;"
            v-html="block.subtitle"
          />
          <div class="mt-5">
            <template v-for="(button,index) in block.buttons">
              <v-btn
                :key="index"
                :outlined="button.outlined"
                :plain="button.plain"
                :rounded="button.rounded"
                :class="`liBlockButton ${button.styleClasses}`"
                @click.prevent="liBtnGo(button)"
                :block="isAppMobile"
                :style="isAppMobile? 'width:100%;':''"
              >
                {{button.label}}
              </v-btn>
            </template>
          </div>
        </div>

      </div>

    </v-img>
  </v-row>

</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  name: "storyHero",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    video(){
      if ("video" in this.block && this.block.video != "") {
        if(this.isAppMobile){
          return this.block.videoMobile;
        } else {
          return this.block.video;
        }
      } else {
        return false;
      }
    },
  },
  methods: {},
};
</script>

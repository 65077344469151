<template>
  <v-row
    :class="`liImageTextButtons2 ${block.type} ${block.styleClasses}`"
    :key="index"
    :ref="`ref_${block.identifier}`"
    :id="`${block.identifier}`"
  >
    <div
      class="inner row"
      :class="`${block.innerStyleClasses}`"
    >
      <v-col
        :cols="`${'imageColumnsMobile' in block && block.imageColumnsMobile !=''?  block.imageColumnsMobile : '12'}`"
        :md="`${'imageColumnsDesktop' in block && block.imageColumnsDesktop !=''?  block.imageColumnsDesktop : '6'}`"
        :class="`liImage pa-0 ${block.imageStyleClasses}`"
      >
        <!-- IMAGE -->
        <v-img
          v-if="block.aspectRatio != ''"
          :src="`${isAppMobile ? `${apiPublicUrl}${block.imageMobile}` : `${apiPublicUrl}${block.image}`}`" 
          :aspect-ratio="`${isAppMobile ? `${block.aspectRatioMobile}` : `${block.aspectRatio}`}`" 
        />
        <v-img
          v-else
          :src="`${isAppMobile ? `${apiPublicUrl}${block.imageMobile}` : `${apiPublicUrl}${block.image}`}`" 
        />
      </v-col>
      <v-col
        :cols="`${'textColumnsMobile' in block && block.textColumnsMobile !=''?  block.textColumnsMobile : '12'}`"
        :md="`${'textColumnsDesktop' in block && block.textColumnsDesktop !=''?  block.textColumnsDesktop : '6'}`"
        :class="`liText ${block.textStyleClasses}`"
      >
        <div class="m-auto w-100">
          <!-- TEXT -->
          <div v-html="block.text" class="pa-0 pa-md-5"/>
          <!-- BUTTONS -->
          <div
            class="mt-10"
            v-if="'buttons' in block && block.buttons.length > 0"
          >
            <template v-for="(button,index) in block.buttons">
              <v-btn
                :key="index"
                :outlined="button.outlined"
                :plain="button.plain"
                :rounded="button.rounded"
                :class="`liBlockButton ${button.styleClasses}`"
                @click.prevent="liBtnGo(button)"
              >
                {{button.label}}
              </v-btn>
            </template>
          </div>
        </div>

      </v-col>
    </div>
  </v-row>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  name: "storyTextImage",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {},
};
</script>

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";



Vue.use(Vuetify);

export default new Vuetify({ rtl: false,
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#363636',
        accent: '#FF4081',
        secondary: '#00FFFF',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252'
      },
      light: {
        primary: '#363636',
        accent: '#e91e63',
        secondary: '#30B1DC',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252'
      }
    }
  }});

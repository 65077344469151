import Vue from "vue";

/**
 * Truncates a string to a given length (200 characters by default)
 *
 * @param str String
 * @param length Int length of the returned string
 */
Vue.filter("trimLength", (str, length) => {
  length = typeof length === "undefined" ? 200 : length;
  if (str.length < length) {
    return str;
  }
  return `${str.substring(0, length)}...`;
});

/**
 * Capitalizes the first letter of a string
 *
 * @param str String
 */
Vue.filter("capitalizeFirstLetter", (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
});

/**
 * Decamelizes a string with/without a custom separator (underscore by default).
 *
 * @param str String in camelcase
 * @param separator Separator for the new decamelized string, standard "_".
 */
Vue.filter("decamelize", (str, separator) => {
  separator = typeof separator === "undefined" ? "_" : separator;
  return str
    .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
    .toLowerCase();
});

Vue.filter("slugify", (str) => {
  return str
    .toString()                     // Cast to string
    .toLowerCase()                  // Convert the string to lowercase letters
    .normalize('NFD')       // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim()                         // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w-]+/g, '')       // Remove all non-word chars
    .replace(/-+/g, '-');        // Replace multiple - with single -
});
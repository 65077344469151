<template>
  <v-footer
    padless
    v-if="footerContent != null"
    style="z-index: 5; background-color: transparent; z-index: 5; position: fixed; left: 0; bottom: 0;"
  >
    <v-row
      justify="center"
      no-gutters
      class="white--text pa-2 "
    >
      <span class="mr-3 text-size-s">
        {{ footerContent.text }}
      </span>

      <a
        @click="tcSheet = !tcSheet"
        class="white--text text-size-s text-decoration-underline"
      >
      
        {{ footerContent.tencs }}
      </a>

    </v-row>

    <!-- BOTTOM SHEET -->
    <v-bottom-sheet v-model="tcSheet">
        <v-sheet
          class="text-left"
          height="80vh"
        >
          <div
            class="col-12 col-md-6 m-auto"
            style="position: relative"
          >
            <div class="text-h5 text-uppercase">
              <v-btn
                @click="tcSheet = !tcSheet"
                color="primary"
                dark
                absolute
                top
                right
                fab
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <div
            class="col-12 col-md-6 m-auto overflow-y-auto"
            style="max-height: 65vh"
            v-html="privacyStatementContent"
          ></div>
          <div class="col-12 col-md-6 m-auto text-center">
            <v-btn
              color="primary"
              @click="tcSheet = !tcSheet"
            >
              {{ footerContent.tencsClose }}
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
  </v-footer>
</template>
<script>
export default {
  name: "liFooter",
  data: () => ({
    tcSheet: false
  }),
  mounted() {
    this.fetchFooterItems();
    this.fetchAppPrivacyStatement();
  },
  watch: {
    language() {
      this.fetchFooterItems();
      this.fetchAppPrivacyStatement();
    },
  },
  computed: {
    footerItems() {
      return this.$store.getters["liApiContent/getCollection"](
        `main_footer_${this.language}`
      );
    },
    footerContent() {
      let result = null;
      if (this.footerItems != null) {
        let block = JSON.parse(this.footerItems[0].content);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
  },
};
</script>

import liApiService from '../services/liApi.service';
export const liApiStore = {
    namespaced: true,
    state: {
        stores:null,
    },
    actions: {
        GET_STORES({
            commit
        }, _data) {
            return liApiService
                .getStores(_data)
                .then(response => {
                        commit('setStores', response);
                        return Promise.resolve();
                    },
                    error => {
                      console.debug(error);
                    });
        },
        POST_FORM({
            commit
        }, _data) {
            // console.log(_data);
            return liApiService
                .postForm(_data)
                .then(response => {
                        commit('emptyFN');
                        return Promise.resolve(response);
                    },
                    error => {
                      console.debug(error);
                    });
        },
        POST_FORM2({
            commit
        }, _data) {
            // console.log(_data);
            return liApiService
                .postForm2(_data)
                .then(response => {
                        commit('emptyFN');
                        return Promise.resolve(response);
                    },
                    error => {
                      console.debug(error);
                    });
        },
        POST_IMAGE({
            commit
        }, _data) {
            // console.log(_data);
            return liApiService
                .postImage(_data)
                .then(response => {
                        commit('emptyFN');
                        return Promise.resolve(response);
                    },
                    error => {
                      console.debug(error);
                    });
        }
        
    },
    mutations: {
        emptyFN(){
            // SILENCE IS GOLDEN
        },
        setStores(state, _data){
            state.stores = _data;
        }
    },
    getters: {
        stores:(state)=> {
            return state.stores;
        },
    }
};
<template>
  <div :class="`liSocialButtons ${block.type} ${block.styleClasses}`" :key="index" :ref="`ref_${block.identifier}`" :id="`${block.identifier}`">
    <div class="inner row mx-0" :class="`${block.innerStyleClasses}`">
      <div class="mx-auto">
        <v-btn v-if="block.instagramLink != ''" large class="px-0 mx-2 liInstagramBackground mb-3 mx-1 pa-0" dark icon :href="block.instagramLink" target="_blank">
          <v-icon color="white" class=""> mdi-instagram </v-icon>
        </v-btn>
        <v-btn v-if="block.faceBookLink != ''" large class="px-0 mx-2 liFacebookBackground mb-3 mx-1 pa-0" dark icon :href="block.faceBookLink" target="_blank">
          <v-icon color="white" class=""> mdi-facebook </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "socialButtons",
  data() {
    return {};
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {},
};
</script>

<template>
  <div :class="`${block.type} ${block.styleClasses}`" :key="index" :ref="`ref_${block.identifier}`" :id="`${block.identifier}`">
    <v-img v-if="isAppMobile" class="liImageBlock" :aspect-ratio="block.aspectRatioMobile" :src="`${apiPublicUrl}${block.imageMobile}`" />
    <v-img v-else class="liImageBlockMobile" :aspect-ratio="block.aspectRatio" :src="`${apiPublicUrl}${block.image}`" />
  </div>
</template>

<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  name: "ImageBlock",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {},
};
</script>

<template>
  <v-row
    :class="`liCollectionBlock ${block.type} ${block.styleClasses}`"
    :key="index"
    :ref="`ref_${block.identifier}`"
    :id="`${block.identifier}`"
  >
    <template v-for="(item,index) in block.collection">
      <v-col
        :key="index"
        :cols="item.cols"
        :md="item.colsMd"
        :lg="item.colsLg"
      >
        <v-card
          class="liCollectionItem"
          elevation="0"
        >
          <v-img
            :height="isAppMobile ? '300': '300'"
            :src="`${apiPublicUrl}${item.image}`"
            position="top center"
          />
          <v-card-title>{{item.label}}</v-card-title>
          <v-card-text class="">
            <v-row
              align="center"
              class="mx-0 mt-4"
            >
              {{item.description}}
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              plain
              class="text-initial text-primary p-0"
              @click.prevent="liBtnGo(item)"
            >
              {{item.linkLabel}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  name: "Collection",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {},
};
</script>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// THIRD PARTY STUFF

// PAGE TITLE 
import VuePageTitle from 'vue-page-title'
Vue.use(VuePageTitle, {
  prefix: '',
  suffix: ''
});
// VUE MASK
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

// PERFECT SCROLLBAR
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
Vue.use(PerfectScrollbar)

// GOOGLE MAPS
// import * as VueGoogleMaps from 'vue2-google-maps'
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyA02WTMWr-fGF1EoNeREuub8oUvPXT27Ao',
//     libraries: 'places', // This is required if you use the Autocomplete plugin
//     // OR: libraries: 'places,drawing'
//     // OR: libraries: 'places,drawing,visualization'
//     // (as you require)
 
//     //// If you want to set the version, you can do so:
//     // v: '3.26',
//   },
 
//   //// If you intend to programmatically custom event listener code
//   //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
//   //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
//   //// you might need to turn this on.
//   // autobindAllEvents: false,
 
//   //// If you want to manually install components, e.g.
//   //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
//   //// Vue.component('GmapMarker', GmapMarker)
//   //// then disable the following:
//   // installComponents: true,
// })
// GEO LOCATION
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

// LINNOX STUFF
// MIXINS
import liMixin from './mixins/liMixin';
Vue.mixin(liMixin)
// FILTERS
import './filters/filters';

// COMPONENTS
// NAVIGATION
import liNav from './components/static/nav';
Vue.component('liNav', liNav);
// FOOTER
import liFooter from './components/static/footer';
Vue.component('liFooter', liFooter);

import li360Carrousel from './components/360/Li360Carrousel';
Vue.component('li360Carrousel', li360Carrousel);



// BLOCKS
// HERO BLOCK
import liBlock_hero from './components/blocks/Hero';
Vue.component('liBlock_hero', liBlock_hero);
// TEXT BLOCK
import liBlock_textBlock from './components/blocks/TextBlock';
Vue.component('liBlock_textBlock', liBlock_textBlock);
// IMAGE BLOCK
import liBlock_imageBlock from './components/blocks/ImageBlock';
Vue.component('liBlock_imageBlock', liBlock_imageBlock);
// COLLECTION BLOCK
import liBlock_collection from './components/blocks/Collection';
Vue.component('liBlock_collection', liBlock_collection);
// IMAGE TEXT BUTTONS BLOCK
import liBlock_imageTextButtons from './components/blocks/ImageTextButtons';
Vue.component('liBlock_imageTextButtons', liBlock_imageTextButtons);
// BUTTONS BLOCK
import liBlock_buttons from './components/blocks/Buttons';
Vue.component('liBlock_buttons', liBlock_buttons);
// COUNTDOWN BLOCK
import liBlock_countdown from './components/blocks/Countdown';
Vue.component('liBlock_countdown', liBlock_countdown);
// SOCIAL BUTTONS
import liBlock_socialButtons from './components/blocks/SocialButtons';
Vue.component('liBlock_socialButtons', liBlock_socialButtons);

// 2.0 BLOCKS
// HERO BLOCK 2.0
import liBlock_storyHero from './components/blocks/StoryHero';
Vue.component('liBlock_storyHero', liBlock_storyHero);
// IMAGE TEXT BUTTONS BLOCK 2.0
import liBlock_storyTextImage from './components/blocks/StoryTextImage';
Vue.component('liBlock_storyTextImage', liBlock_storyTextImage);


// SPECIAL BLOCKS
import liBlock_commanderHero from './components/blocks/CommanderHero';
Vue.component('liBlock_commanderHero', liBlock_commanderHero);

import liBlock_recapHero from './components/blocks/RecapHero';
Vue.component('liBlock_recapHero', liBlock_recapHero);
import liBlock_recapBlock from './components/blocks/RecapBlock';
Vue.component('liBlock_recapBlock', liBlock_recapBlock);

import liBlock_storyText360 from './components/blocks/StoryText360';
Vue.component('liBlock_storyText360', liBlock_storyText360);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

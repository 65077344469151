<template>
  <!-- color="primary" -->
  <v-app-bar
    fixed
    dark
    class="liNav bg-black"
    :class="{ scrolled: scrollPosition > 300 }"
  >
    <v-spacer />
    <!-- START / HOME BUTTON / LOGO -->
    <v-btn
      key="home-desktop"
      text
      class="liLogo dekstop mx-3 lowerCase ml-md-10"
      :style="scrollPosition < 300 ? 'height: 65px;':''"
      :to="`/${$route.params.language != undefined ? $route.params.language+'/home' : '/country-select'}`"
      v-if="!isAppMobile"
    >
      <v-img
        :width="scrollPosition > 300 ? 75 : 100"
        :src="logoImageFull"
      />
    </v-btn>
    <v-btn
      key="home-mobile"
      text
      class="liLogo mx-3 lowerCase ml-md-10"
      :to="`/${
        $route.params.language != undefined ? $route.params.language : ''
      }`"
      :width="scrollPosition > 300 ? 30 : 50"
      :height="scrollPosition > 300 ? 30 : 50"
      v-else
    >
      <v-img
        :width="scrollPosition > 300 ? 50 : 75"
        :src="logoImageFull"
      />
    </v-btn>
    <!-- EMD / HOME BUTTON / LOGO -->
    <v-spacer />
    <!-- START / NAVIGATION / DESKTOP -->
    <template v-if="!isAppMobile && navigationContent !=null">
      <template v-for="(nav, index) in navigationContent">
        <v-btn
          v-if="nav.status != 0"
          :key="index"
          text
          :to="nav.url"
        >
          {{ nav.title }}
        </v-btn>
      </template>
    </template>
    <!-- Language selector -->
    <v-menu
      bottom
      left
      v-if="defaultTextContent != null  &&  $route.name != 'CountrySelect' &&  $route.name != 'Confirmation'"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          v-bind="attrs"
          v-on="on"
          style="
            padding: 0px;
            max-width: 22px;
            height: unset;
            min-width: unset;
          "
          class="bg-black"
        >
          <v-img
            v-if="language != null"
            :src="flags[language]"
            :width="isAppMobile? '25px' : '35px'"
          />
          <v-img
            v-else
            :src="flags['nl']"
            :width="isAppMobile? '25px' : '35px'"
          />
        </v-btn>
      </template>
      <v-list dense>
        <template v-for="(item, i) in defaultTextContent.languageSelector.languages">
          <v-list-item
            v-if="item.status == 1"
            :key="i"
            :link="language != item.shortcode"
            :disabled="language == item.shortcode"
            :style="language == item.shortcode ? 'background-color:#f1f1f1;':'' "
          >

            <v-list-item-title @click.prevent="setLanguage(item.shortcode)">
              <v-img
                :src="flags[item.shortcode]"
                width="20px"
                height="15px;"
                style="display: inline-block; margin-right: 10px"
              />
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import logo from "../../assets/images/logo.svg";
import logoFull from "../../assets/images/logo_full.svg";

export default {
  name: "liNav",
  data() {
    return {
      logoImage: logo,
      logoImageFull: logoFull,
    };
  },
  created() {
    this.fetchNavigationItems();
  },
  methods:{
    setLanguage(languageCode){
      if(languageCode == "be_fr"){
        document.documentElement.setAttribute("lang", 'fr');
      } else {
        document.documentElement.setAttribute("lang", this.languageCode);
      }
      this.$router.push(`/${languageCode}/home`);
    }
    
  }
};
</script>

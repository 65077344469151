<template>
  <v-app>
    <liNav v-if="$route.name != 'Home'" />
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view :key="`${$route.name}${$route.name == 'Steps'? $route.params.step:''}${$route.name == 'Share'? $route.params.name:''}-${$route.params.language}`" />
      </transition>
    </v-main>
    <liFooter  v-if="$route.name != 'Home'" />
    <!--
    <v-system-bar v-if="$route.name != 'Home'" window class="bg-black text-primary " style="width: 100vw; position:fixed; top:unset; bottom:0;">
      <div class="w-100 text-center  font-weight-bold text-size-s">
        This is a demo environment - Registrations are not processed
      </div>
    </v-system-bar>
    -->
  </v-app>
</template>
<script>
export default {
  name: "PMELEGENDflyWithApp",
  data: () => ({}),
  mounted() {
    if (this.defaultTexts == null) {
      this.fetchAppDefaultTexts();
    }
  },
  watch: {
    language() {
      // this.fetchNavigationItems();
      this.fetchAppDefaultTexts();
    }
  },
  computed: {},
};
</script>
<style lang="scss">
@import "./scss/index.scss";
</style>

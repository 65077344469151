<template>
  <v-row class="mx-0" :class="`${block.type} ${block.styleClasses}`" :key="index" :id="`${block.identifier}`" :ref="`ref_${block.identifier}`">
    <v-img :src="`${apiPublicUrl}${block.image}`" :position="block.imagePosition" :height="block.height" :min-height="block.minHeight" :max-height="block.maxHeight" :width="block.width" :min-width="block.minWidth" :max-width="block.maxWidth" class="hero liHeroBlock fill-height">
      <video v-if="video" autoplay playsinline loop preload muted style="position: absolute; width: 100%; height: 100%; object-fit: cover; object-position: center top">
        <source :src="video" type="video/mp4" />
      </video>
      <!-- CAMPAIGN LOGO -->
      <img :src="defaultTextContent.campaignLogo" class="liCampaignLogo" />
      <div class="d-flex inner fill-height">
        <div class="leftBlock">
          <!-- TITLE -->
          <h1 class="title text-lg-h2 text-md-h3 text-sm-h4 text-h4 text-uppercase mb-2 font-weight-bold white--text liTextShadow no-hyphens" v-html="block.title" />
          <!-- SUBTITLE -->
          <div v-if="block.subtitle != '' && !isAppMobile" class="text-p white--text liTextShadow" style="z-index: 2; position: relative" v-html="block.subtitle" />
          <div class="mt-5">
            <template v-for="(button, index) in block.buttons">
              <v-btn :key="index" :outlined="button.outlined" :plain="button.plain" :rounded="button.rounded" :class="`liBlockButton ${button.styleClasses}`" @click.prevent="liBtnGo(button)" :block="isAppMobile" :style="isAppMobile ? 'width:100%;' : ''">
                {{ button.label }}
              </v-btn>
            </template>
          </div>
        </div>
      </div>
    </v-img>
  </v-row>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  name: "Hero",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    video() {
      if ("video" in this.block && this.block.video != "") {
        if (this.isAppMobile) {
          return this.block.videoMobile;
        } else {
          return this.block.video;
        }
      } else {
        return false;
      }
    },
  },
  methods: {},
};
</script>

<template>
  <v-row
    :class="`liStory360 ${block.type} ${block.styleClasses}`"
    :key="index"
    :ref="`ref_${block.identifier}`"
    :id="`${block.identifier}`"
  >
    <div
      class="inner row"
      :class="`${block.innerStyleClasses}`"
    >
      <v-col
        :cols="`${'imageColumnsMobile' in block && block.imageColumnsMobile !=''?  block.imageColumnsMobile : '12'}`"
        :md="`${'imageColumnsDesktop' in block && block.imageColumnsDesktop !=''?  block.imageColumnsDesktop : '6'}`"
        :class="`liImage pa-0 ${block.imageStyleClasses}`"
      >
      <div style="position:relative;  height:100%;"
      :style="`${isAppMobile? 'left: 20%; width:80%': 'width:100%;' }`"
      >
        <div :class="`liHighlight liStep${currentStep}`"/>
      
        <li360Carrousel
          :images="images"
          :speed="20"
          class="li360 w-100"
          infinite
          keep-position
          reverse
          image-class="w-100"
          ref="rotator"
          @loading="loading360"
          @loaded="loaded360"
          @starting="starting360"
          @stopping="stopping360"
          @refreshing="refreshing360"
          @refreshed="refreshed360"
        >
          <p>Loading</p>
        </li360Carrousel>
        <div v-if="isAppMobile" style="position: absolute; bottom:30%; left:-20%; width: 190px;">
          <v-btn
            class="detailBtn"
            :class="currentStep == 4? 'active' : ''"
            text
            @click.prevent="rotatorTo(4)"
          >
            EMBROIDED ARTWORK
          </v-btn>
          <v-btn
           class="detailBtn"
            :class="currentStep == 8? 'active' : ''"
            text
            @click.prevent="rotatorTo(8)"
          >
            BUTTON FLY
          </v-btn>
          <v-btn
           class="detailBtn"
            :class="currentStep == 0? 'active' : ''"
            text
            @click.prevent="rotatorTo(0)"
          >
            CURVED BACKPOCKETS
          </v-btn>
          <v-btn
           class="detailBtn"
            :class="currentStep == 6? 'active' : ''"
            text
            @click.prevent="rotatorTo(6)"
          >
            STRAIGHT LEG
          </v-btn>
          <v-btn
           class="detailBtn"
            :class="currentStep == 10? 'active' : ''"
            text
            @click.prevent="rotatorTo(10)"
          >
            19 CM LEG OPENING
          </v-btn>
          </div>
        </div>

      </v-col>
      <v-col
        :cols="`${'textColumnsMobile' in block && block.textColumnsMobile !=''?  block.textColumnsMobile : '12'}`"
        :md="`${'textColumnsDesktop' in block && block.textColumnsDesktop !=''?  block.textColumnsDesktop : '6'}`"
        :class="`liText ${block.textStyleClasses}`"
      >
        <div class="m-auto w-100">
          <!-- TEXT -->
          <div
            v-html="block.text"
            class="pa-0 pa-md-5"
          />
          <div v-if="!isAppMobile">
          <v-btn
            class="detailBtn"
            :class="currentStep == 4? 'active' : ''"
            text
            @click.prevent="rotatorTo(4)"
          >
            EMBROIDED ARTWORK
          </v-btn>
          <v-btn
           class="detailBtn"
            :class="currentStep == 8? 'active' : ''"
            text
            @click.prevent="rotatorTo(8)"
          >
            BUTTON FLY
          </v-btn>
          <v-btn
           class="detailBtn"
            :class="currentStep == 0? 'active' : ''"
            text
            @click.prevent="rotatorTo(0)"
          >
            CURVED BACKPOCKETS
          </v-btn>
          <v-btn
           class="detailBtn"
            :class="currentStep == 6? 'active' : ''"
            text
            @click.prevent="rotatorTo(6)"
          >
            STRAIGHT LEG
          </v-btn>
          <v-btn
           class="detailBtn"
            :class="currentStep == 10? 'active' : ''"
            text
            @click.prevent="rotatorTo(10)"
          >
            19 CM LEG OPENING
          </v-btn>
          </div>
        </div>

      </v-col>
    </div>
  </v-row>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;

export default {
  name: "storyText360",
  data() {
    return {
      currentStep: 0,
      apiPublicUrl: API_URL,
      images: [
        "/assets/images/360/0.png",
        "/assets/images/360/1.png",
        "/assets/images/360/2.png",
        "/assets/images/360/3.png",
        "/assets/images/360/4.png",
        "/assets/images/360/5.png",
        "/assets/images/360/6.png",
        "/assets/images/360/7.png",
        "/assets/images/360/8.png",
        "/assets/images/360/9.png",
        "/assets/images/360/10.png",
        "/assets/images/360/11.png",
        "/assets/images/360/12.png",
      ],
    };
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    loading360() {
      console.log("loading360");
    },
    loaded360() {
      console.log("loaded360");
    },
    starting360(e) {
      console.log("starting360", e);
    },
    stopping360(e) {
      console.log("stopping360", e);
       this.currentStep = e.position;
    },
    refreshing360(e) {
      console.log("refreshing360", e);
    },
    refreshed360(e) {
      console.log("refreshed360", e);
    },
    rotatorTo(number) {
      // let number  = Math.floor(Math.random() * 12);
      this.currentStep = NaN;  
      setTimeout(() => {
        this.currentStep = number;  
      }, 500);
      
      this.$refs["rotator"].slideTo(number);
    },
  },
};
</script>

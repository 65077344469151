<template>
  <v-container
    class="w-100 px-0"
    fluid
  >
    <template v-if="pageContent != null && loading == false">
      <!-- hero -->
      <v-row class="mx-0 px-0">
        <v-img
          :src="`${apiPublicUrl}${pageContent.content.hero.image}`"
          class="hero"
          min-height="100vh"
          max-height="100vh"
          :image-position="pageContent.content.hero.imagePosition"
        >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <div
              class="hero-item col col-12 bg-black px-5 pt-5 text-white "
              style="max-width:960px;"
            >
              <h1
                class="text-center title text-lg-h2 text-md-h3 text-sm-h3 text-h5 text-uppercase text-white li-text-shadow mb-3 font-weight-bold"
                v-html="pageContent.content.hero.title"
              />
              <div
                class="mt-3 countries "
                align="center"
                justify="center"
              >
                <template v-for="(country, index) in pageContent.content.languageSelector">
                  <v-card
                    :key="index"
                    class="liCountry bg-black text-center d-inline-block"
                    :style="`width:100px; margin: ${isAppMobile? '2px':'15px'};`"
                    @click.prevent="setCountry(country.shortcode)"
                  >
                    <img
                      :src="flags[country.flag]"
                      class="mx-auto d-block mb-2"
                      style="border:2px solid #fff;"
                    />
                    <div class="pa-0 d-block">
                      <p class="pa-1 pb-0 text-white text-size-s">
                        {{country.name}}
                      </p>
                    </div>
                  </v-card>

                </template>
              </div>

            </div>
          </v-row>
        </v-img>
      </v-row>
    </template>
    <!-- LOADING -->
    <template v-else>
      <div
        class="text-center w-100 d-flex"
        style="height: 50vh"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          class="m-auto"
        />
      </div>
    </template>

  </v-container>
</template>

<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  title: "CountrySelect",
  name: "CountrySelect",
  data() {
    return {
      apiPublicUrl: API_URL,
      loading: true
    };
  },
  created() {
    this.fetchURL();
  },
  watch: {
    pageContent(val) {
      if (val != null) {
        this.$title = this.pageContent.default.pageTitle;
      }
    },
  },
  beforeMount() {
    this.getLocation();
  },
  mounted() {
    if(this.dev){
      console.log("START    -- VIEW CountrySelect");
      console.log("Language:  ", this.language);
      console.log("Website:   ", this.website);
      console.log("FullUrl:   ", this.fullUrl);
      console.log("END      -- VIEW CountrySelect");
    }
    this.fetchPageData();
  },
  computed: {
    page() {
      // IF THE PAGE IS AN ACTUAL PAGE
      // return this.$store.getters["liApiContent/getPage"](this.fullUrl);
      // IF THE PAGE IS A STATIC BLOCK
      return this.$store.getters["liApiContent/getCollection"](
        `page_data_${this.language}_${this.fullUrl}`
      );
    },

    pageContent() {
      // IF THE PAGE IS AN ACTUAL PAGE
      // return this.page != null ? JSON.parse(this.page.content) : null;
      // IF THE PAGE IS A STATIC BLOCK
      let result = null;
      if (this.page != null) {
        let block = JSON.parse(this.page[0].content);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
  },
  methods: {
    setCountry(languageCode){
      // console.log('languageCode',languageCode);
      if(languageCode == "be_fr"){
        document.documentElement.setAttribute("lang", 'fr');
      } else if(languageCode == "be"){
        document.documentElement.setAttribute("lang", 'nl');
      } else if(languageCode == "ch"){
        document.documentElement.setAttribute("lang", 'DE');
      } else if(languageCode == "at"){
        document.documentElement.setAttribute("lang", 'DE');
      }  else {
        document.documentElement.setAttribute("lang", languageCode.toUpperCase());
      }
      this.$router.push(`/${languageCode}/home`);
    },
   getCountry() {
	

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (timezone === "" || !timezone) {
        return null;
      }

      const _country = this.timezones[timezone].c[0];
      const country = this.countries[_country];
      return {
        shortcode  : _country,
        name : country
      };
    },
    getLocation() {
      const val = this.getCountry();
      // console.log('val',val)
      if(val != null){
        if (this.allowedLanguages.includes(val.shortcode)){
          this.setCountry(val.shortcode.toLowerCase());
        } else {
          this.loading = false;  
        }
      } else {
        this.loading = false;
      }
       
      // this.$getLocation(
      //   {
      //     enableHighAccuracy: false, //defaults to false
      //     timeout: 0,
      //     maximumAge: 0,
      //   },
      //   false
      // )
      //   .catch((e) => {
      //     console.error(e);
      //   })
      //   .then((coordinates) => {
      //     console.log(coordinates);
      //   });
    },
  },
};
</script>

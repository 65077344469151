<template>
  <div
    :class="`liButtons ${block.type} ${block.styleClasses}`"
    :key="index"
    :ref="`ref_${block.identifier}`"
    :id="`${block.identifier}`"
  >
  <div
      class="inner row"
      :class="`${block.innerStyleClasses}`"
    >
   <template v-for="(button,index) in block.buttons">

              <v-btn
                :key="index"
                :outlined="button.outlined"
                :plain="button.plain"
                :rounded="button.rounded"
                :class="`liButton ${button.styleClasses}`"
                @click.prevent="liBtnGo(button)"
              >
                {{button.label}}
              </v-btn>
            </template>
  </div>
  </div>

</template>
<script>
export default {
  name: "imageTextButtons",
  data() {
    return {};
  },
  props:{
    block:{
        type:Object,
        required:true
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {},
};
</script>

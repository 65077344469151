<template>
  <v-row class="mx-0" :class="`${block.type} ${block.styleClasses}`" :key="index" :id="`${block.identifier}`" :ref="`ref_${block.identifier}`" v-if="defaultTextContent != null">
    <v-img
      :src="`${apiPublicUrl}${block.image}`"
      :height="block.height"
      :position="block.imagePosition"
      :min-height="block.minHeight"
      :max-height="block.maxHeight"
      :width="block.width"
      :min-width="block.minWidth"
      :max-width="block.maxWidth"
      class="countdown liCountdownBlock"
      v-if="timeLeft != 'done'"
    >
      <v-progress-circular v-if="days == null" indeterminate color="warning" style="position: absolute; top: 25%; left: 50%; z-index: 3; transform: translateX(-50%)" />
      <v-container class="mt-15 fill-height" style="max-width: 800px" v-if="days != null && timeLeft == null">
        <div class="align-self-start">
          <h1 class="pme-heading title text-lg-h2 text-md-h3 text-sm-h4 text-h4 text-uppercase mb-2 font-weight-bold white--text liTextShadow no-hyphens" v-html="block.title" />
          <div v-if="block.subtitle != ''" class="text-p white--text liTextShadow" v-html="block.subtitle" />
          <div class="liCountdownContainer" :class="isWinOrMac.toLowerCase()">
            <span class="liCountdownItemText D">DAYS</span>
            <div class="liCountdownItem" v-text="days" />
            <span class="liCountdownItemText H">HOURS</span>
            <div class="liCountdownItem" v-text="hours" />
            <span class="liCountdownItemText M">MINUTES</span>
            <div class="liCountdownItem" v-text="minutes" />
          </div>
        </div>
        <div class="align-self-end" style="margin-bottom: 13%">
          <v-btn large class="px-0 mx-auto liInstagramBackground mb-3 mr-3" dark icon href="https://www.instagram.com/pmelegend/" target="_blank">
            <v-icon color="white" class="mb-1"> mdi-instagram </v-icon>
          </v-btn>
          <v-btn large class="px-0 mx-auto liFacebookBackground mb-3 mr-3" dark icon href="https://www.facebook.com/PMELegend" target="_blank">
            <v-icon color="white" class="mb-1"> mdi-facebook </v-icon>
          </v-btn>
          <div v-if="block.description != ''" class="text-p white--text liTextShadow mb-15" v-html="block.description" />
        </div>
      </v-container>
    </v-img>
    <v-img :src="endImage" height="100%" min-height="100vh" position="bottom" class="countdown liCountdownBlock done dekstop" v-if="timeLeft != null && timeLeft == 'done'">
      <v-container class="mt-15 fill-height" style="max-width: 800px; position: relative" v-if="days != null && timeLeft == 'done'">
        <div class="align-self-start headerItem">
          <h1 class="pme-heading title text-lg-h2 text-md-h3 text-sm-h3 text-h3 text-uppercase mb-2 font-weight-bold white--text liTextShadow no-hyphens" v-html="block.titleDone" />
        </div>
        <img :src="defaultTextContent.campaignLogo" class="liCampaignLogoEnd" />
        <div class="desktopSpacer" />
        <div v-if="block.descriptionDone != ''" class="text-p white--text description li-text-shadow" v-html="block.descriptionDone" />
        <v-btn large class="px-0 mx-auto liInstagramBackground mb-3 mr-3" dark icon href="https://www.instagram.com/pmelegend/" target="_blank">
          <v-icon color="white" class="mb-1"> mdi-instagram </v-icon>
        </v-btn>
        <v-btn large class="px-0 mx-auto liFacebookBackground mb-3 mr-3" dark icon href="https://www.facebook.com/PMELegend" target="_blank">
          <v-icon color="white" class="mb-1"> mdi-facebook </v-icon>
        </v-btn>
        <img :src="endRibbon" class="endRibbon" />
      </v-container>
    </v-img>
    <v-img :src="endImageMobile" height="100%" min-height="100vh" position="bottom" class="countdown liCountdownBlock done mobile" v-if="timeLeft != null && timeLeft == 'done'">
      <v-container class="mt-15 fill-height" style="max-width: 800px; position: relative" v-if="days != null && timeLeft == 'done'">
      <div class="align-self-start headerItem">
        <h1 class="pme-heading title text-lg-h2 text-md-h3 text-sm-h3 text-h3 text-uppercase mb-2 font-weight-bold white--text liTextShadow no-hyphens" v-html="block.titleDone" />
      </div>
      <div class="mobileSpacer" />
      <img :src="endRibbon" class="endRibbon" />
      <div style="margin-top: 50px">
        <v-btn large class="px-0 mx-auto liInstagramBackground mb-3 mr-3" dark icon href="https://www.instagram.com/pmelegend/" target="_blank">
          <v-icon color="white" class="mb-1"> mdi-instagram </v-icon>
        </v-btn>
        <v-btn large class="px-0 mx-auto liFacebookBackground mb-3 mr-3" dark icon href="https://www.facebook.com/PMELegend" target="_blank">
          <v-icon color="white" class="mb-1"> mdi-facebook </v-icon>
        </v-btn>
        <div v-if="block.descriptionDone != ''" class="text-p black--text description" v-html="block.descriptionDone" />
      </div>
       </v-container>
    </v-img>
  </v-row>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
import endImage from "../../assets/images/pme-eind-2.jpg";
import endImageMobile from "../../assets/images/pme-eind-mobile.jpg";
import endRibbon from "../../assets/images/pme-end-ribbon-2.jpg";
export default {
  name: "Countdown",
  data() {
    return {
      apiPublicUrl: API_URL,
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      timeLeft: null,
      endImage: endImage,
      endRibbon: endRibbon,
      endImageMobile:endImageMobile
    };
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.countdown();
  },
  computed: {
    countDownDate() {
      return new Date("Dec 13, 2022 23:59:59").getTime();
    },
  },
  methods: {
    countdown() {
      var myfunc = setInterval(() => {
        var now = new Date().getTime();
        var timeleft = this.countDownDate - now;
        // Calculating the days, hours, minutes and seconds left
        let days;
        let hours;
        let minutes;
        let seconds;
        days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
        hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
        days = days < 10 ? `0${days}` : days;
        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;
        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
        // Display the message when countdown is over
        if (timeleft <= 0) {
          clearInterval(myfunc);
          this.timeLeft = "done";
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
.liCountdownContainer {
  width: 300px;
  margin: 70px auto 50px auto;
  display: flex;
  transform: scale(2);
  .liCountdownItemText {
    font-family: "Univers LT Std Condensed", sans-serif !important;
    color: #fff;
    font-size: 8px;
    position: absolute;
    z-index: 0;
    top: -8px;
    &.D {
      left: 8px;
    }
    &.H {
      left: 115px;
    }
    &.M {
      right: 45px;
    }
  }
  .liCountdownItem {
    font-size: 50px;
    color: #fff;
    font-family: "Univers LT Std Condensed", sans-serif !important;
    letter-spacing: 20px !important;
    position: relative;
    margin: 0 12px;
    &:before,
    &:after {
      position: absolute;
      z-index: -1;
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(31, 31, 31, 1) 53%, rgba(54, 54, 54, 1) 100%);
      width: 40px;
      height: 55px;
      content: "";
      top: 4px;
    }
    &:before {
      left: -8px;
      border-radius: 5px 1px 1px 5px;
    }
    &::after {
      right: 11px;
      border-radius: 1px 5px 5px 1px;
    }
  }
  &.win {
    .liCountdownItem,
    .liCountdownItemText {
      line-height: normal;
    }
  }
}
.liCampaignLogoEnd {
  position: absolute;
  top: -40px;
  right: 0;
  width: 230px;
  z-index: 3;
}
.liCountdownBlock {
  &.done {
    .container {
      &.fill-height {
        display: block;
      }
    }
    &.dekstop {
      .headerItem {
        max-width: 50%;
        position: relative;
        z-index: 2;
      }
      // display: none;
      .description {
        margin-top: 70px;
        margin-bottom: 15px;
      }
      .description,
      .liInstagramBackground,
      .liFacebookBackground {
        position: relative;
        z-index: 2;
      }

      .endRibbon {
        // position: absolute;
        // z-index: 1;
        // left: calc(50% - calc(50vw + 10% / 2));
        // top: 50vh;
        // width: calc(100vw + 10%);
        // transform: rotate(-5deg);
        position: relative;
        z-index: 1;
        left: calc(45% - 50vw);
        width: calc(100vw + 10%);
        transform: rotate(-5deg);
      }
    }
    &.mobile {
      display: none;
      .description {
        margin-bottom: 20%;
      }
      .endRibbon {
        position: absolute;
        z-index: 1;
        width: calc(100% + 10px);
        transform: rotate(-5deg);
      }
    }
  }
}

@media screen and (max-width: 900px) {
}
@media screen and (max-width: 768px) {
  .liCountdownBlock {
    &.done {
      .container {
        &.fill-height {
          display: flex;
        }
      }
      &.dekstop {
        display: none;
      }
      &.mobile {
        display: flex;
        .headerItem {
          max-width: 100%;
        }

        .endRibbon {
          left: -255px;
          top:135px;
          width: calc(240% + 10px);
        }
        .mobileSpacer {
          width: 100%;
          height:0px;
          display: block;
        }
      }
    }
  }
  .liCampaignLogoEnd {
    display: none;
  }
  .liCountdownContainer {
    transform: scale(1.5);
    margin: 50px auto 70px auto;
  }
}
@media screen and (max-width: 400px) {
  .liCountdownContainer {
    transform: scale(1);
    margin: 15px auto 170px auto;
  }
  .liCountdownBlock {
    &.done {
      &.mobile {
        .endRibbon {
          left: -255px;
          top:175px;
          width: calc(240% + 10px);
        }
        .mobileSpacer {
          width: 100%;
          height: 60px;
          display: block;
        }
      }
    }
  }
}
</style>

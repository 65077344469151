<template>
  <div class="li360Carrousel">
    <template v-if="isLoaded">
      <img
        draggable="false"
        :src="carousel.currentPath"
        :class="imageClass"
        @mouseup="handleMouseUp"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseleave="handleMouseLeave"
        @touchend="handleTouchEnd"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
      />
    </template>

    <slot
      class="li360Carrousel__loading"
      v-else
    ></slot>
  </div>
</template>

<script>
import ImagesLoader from "../../utilities/imagesLoader";
export default {
  name: "li360Carrousel",
  props: {
    images: {
      type: Array,
      required: true,
      default: () => [],
    },
    imageClass: {
      type: String,
      default: null,
    },
    speed: {
      type: Number,
      default: 10,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    infinite: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    keepPosition: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isLoaded: false,
    li_step: 0,
    li_total: 0,
    li_distance: 0,
    li_direction: "forewards",
    carousel: {
      current: 0,
      currentPath: null,
    },
    mouse: {
      isMoving: false,
      savedPositionX: 0,
      currentPositionX: 0,
    },
  }),
  methods: {
    slideTo(position) {
      this.li_total = this.images.length;
      this.li_direction =
        position > this.carousel.current ? "forewards" : "backwards";
      this.li_step = 0;

      console.log("new", position);
      console.log("current", this.carousel.current);
      console.log("direction", this.li_direction);

      if (this.li_direction == "forewards") {
        this.li_distance = position - this.carousel.current;
        console.log("distance", this.li_distance);
      } else {
        this.li_distance = this.carousel.current - position;
        console.log("distance", this.li_distance);
      }

      if (this.carousel.current != position) {
        if (this.images[position]) {
            this.moveTo(); 
          // this.carousel.current = position;
          // this.carousel.currentPath = this.images[position === 0 ? position : position - 1];
        }
      }
    },
    moveTo() {
      //  create a loop function
      setTimeout(() => {
        //  call a 3s setTimeout when the loop is called
        this.li_step++; //  increment the counter
        if (this.li_step < this.li_distance + 1) {
          if (this.li_direction == "forewards") {
            this.carousel.current++;
          } else {
            this.carousel.current--;
          }
          console.log('this.carousel.current',this.carousel.current);
          this.carousel.currentPath =
            this.images[
              this.carousel.current === 0
                ? this.carousel.current
                : this.carousel.current - 1
            ];
          //  if the counter < 10, call the loop function
          this.moveTo(); //  ..  again which will trigger another
        }
      }, 50);
    },
    handleLoading() {
      return ImagesLoader(this.images).then(() => {
        this.isLoaded = true;
      });
    },
    handleMouseUp() {
      this.mouse.isMoving = false;
      this.$emit("stopping", { position: this.carousel.current });
    },
    handleMouseLeave() {
      if (this.mouse.isMoving) {
        this.mouse.isMoving = false;
        this.$emit("stopping", { position: this.carousel.current });
      }
    },
    handleMouseDown(event) {
      if (!this.disabled) {
        this.mouse.savedPositionX = event.pageX;
        this.mouse.isMoving = true;
        this.$emit("starting", { position: this.carousel.current });
      }
    },
    handleTouchStart(event) {
      event.preventDefault();
      if (!this.disabled) {
        this.mouse.savedPositionX = event.touches[0].pageX;
        this.mouse.isMoving = true;
        this.$emit("starting");
      }
    },
    handleTouchEnd() {
      this.mouse.isMoving = false;
      this.$emit("stopping");
    },
    handleTouchMove(event) {
      event.preventDefault();
      this.handleMovement(event.touches[0].pageX);
    },
    handleMouseMove(event) {
      this.handleMovement(event.pageX);
    },
    handleMovement(currentPosition) {
      if (this.mouse.isMoving) {
        this.mouse.currentPositionX = currentPosition;
        const distance =
          this.mouse.currentPositionX - this.mouse.savedPositionX;
        if (Math.abs(distance) > this.speed) {
          this.mouse.savedPositionX = this.mouse.currentPositionX;
          if (
            (distance > 0 && !this.reverse) ||
            (distance < 0 && this.reverse)
          ) {
            this.slideToRight();
          } else {
            this.slideToLeft();
          }
        }
      }
    },
    slideToRight() {
      if (this.carousel.current < this.images.length) {
        this.carousel.current += 1;
        this.carousel.currentPath = this.images[this.carousel.current - 1];
      } else if (this.infinite) {
        this.carousel.current = 0;
        this.carousel.currentPath = this.images[this.carousel.current];
      }
    },
    slideToLeft() {
      if (this.carousel.current > 1) {
        this.carousel.current -= 1;
        this.carousel.currentPath = this.images[this.carousel.current - 1];
      } else if (this.infinite) {
        this.carousel.current = this.images.length;
        this.carousel.currentPath = this.images[this.carousel.current - 1];
      }
    },
  },
  beforeMount() {
    this.$emit("loading");
    this.handleLoading().then(() => {
      this.$emit("loaded");
    });
  },
  created() {
    this.carousel.currentPath = this.images[this.carousel.current];
  },
  watch: {
    images: {
      handler() {
        this.$emit("refreshing");
        this.handleLoading().then(() => {
          const positionExist = this.images[this.carousel.current];
          if (this.keepPosition && positionExist) {
            this.slideTo(this.carousel.current);
          } else {
            this.slideTo(0);
          }
          this.$emit("refreshed");
        });
      },
    },
  },
};
</script>
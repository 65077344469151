<template>
  <div
    :class="`${block.type} ${block.styleClasses}`"
    :key="index"
    :ref="`ref_${block.identifier}`"
    :id="`${block.identifier}`"
  >
    <div
      class="liTextBlock"
      v-html="block.text"
    >

    </div>
  </div>

</template>
<script>
export default {
  name: "TextBlock",
  data() {
    return {};
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {},
};
</script>
